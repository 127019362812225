import React, { Component } from 'react';
import Button from '../../../../../components/Button';
import UploadBanking from './UploadBankingModal';
import CredoModal from '../../../../../components/CredoModal';
import { getBankingList } from '../lenderServices.action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Preview from '../../../../../assets/images/eye.png';
import BankDocModal from './BankDocModal';
import { API_TRIGGER_BANK_STMT } from '../../../../../utils/APIUrls';
import { POST } from '../../../../../utils/webAPI.service';
import { loaderModal } from '../../../../../store/actions';

class BankingShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalType: null,
      fileUrl: null,
      fileName: null,
    };
  }

  toggleModal = (modalType, fileName, fileUrl) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
      fileName,
      fileUrl,
    });
  };

  getBankingList = () => {
    let { actions, loanId } = this.props;
    let { getBankingList, loaderModal } = actions;
    loaderModal(true);
    getBankingList(loanId);
    loaderModal(false);
  };

  componentDidMount() {
    this.getBankingList();
  }

  triggerBankStmt = () => {
    let { loanId, refresh } = this.props;
    POST(API_TRIGGER_BANK_STMT(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Bank stmt send successfully');
          this.props.closeAction();
          refresh(loanId);
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    let { fileName, fileUrl } = this.state;
    let { bankDetails = [] } = this.props || {};
    return (
      <>
        <div className='entity-kyc-wrapper'>
          <h3 className='card_heading'> Banking</h3>
          <div className='row mt-4 d-flex justify-content-end'>
            <button
              className='card_add_button mr-5'
              onClick={this.triggerBankStmt}
            >
              Share Bank Stmt.
            </button>
            <Button
              className='mr-4  p-3'
              onClick={() => this.toggleModal(UploadBanking)}
            >
              Upload Banking
            </Button>
          </div>
          <table className='m-5 width'>
            <thead>
              <th>File Name</th>
              <th width='150'>Preview</th>
            </thead>
            <tbody>
              {bankDetails?.map((ele, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <img src={File}></img>
                      {ele?.fileName}
                    </td>
                    <td className='span'>
                      <span>
                        <img
                          className='cursor ml-3'
                          alt=''
                          onClick={() =>
                            this.toggleModal(
                              BankDocModal,
                              ele?.fileName,
                              ele?.fileUrl
                            )
                          }
                          src={Preview}
                        ></img>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <CredoModal
          isOpen={this.state?.isModalOpen}
          styles={
            this.state?.modalType === BankDocModal
              ? {
                  content: {
                    width: '80%',
                    height: '80%',
                  },
                }
              : {
                  content: {
                    width: '60rem',
                    height: '30rem',
                  },
                }
          }
          RenderingComponent={this.state.modalType}
          closeAction={() => this.toggleModal('')}
          fileName={fileName}
          fileUrl={fileUrl}
          loanId={this.props.loanId}
          getBankingList={() => this.getBankingList()}
        />
      </>
    );
  }
}
const mapStateToProps = ({ LenderServicesReducer }) => ({
  bankDetails: LenderServicesReducer?.bankDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBankingList,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankingShare);
