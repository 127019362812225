import React, { Component } from 'react';

export default class BankDocModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCpoDocument = () => {
    let fileUrl = this.props.fileUrl;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create an object URL from the Blob
        const objectURL = URL.createObjectURL(blob);
        this.setState({
          image: fileUrl ? fileUrl : objectURL,
        });
      });
  };

  componentDidMount = () => {
    this.getCpoDocument();
  };

  render() {
    let { fileName, fileUrl } = this.props;
    return (
      <>
        <div className='compare-details-wrapper digilocker-document-wrapper'>
          <div className='preview_file'>
            {fileUrl?.toLowerCase()?.includes('pdf') ? (
              <iframe
                title='pdfDoc'
                className='profile_img  preview_img'
                src={this.state?.image}
              ></iframe>
            ) : (
              <img
                src={this.state?.image}
                alt='user_profile'
                className='profile_img  '
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
