import React, { Component } from 'react';

export default class UserProfileModal extends Component {
  render() {
    let { propsData } = this.props || {},
      { photos, promoterData } = propsData || {},
      { faceLiveness } = photos || {};
    return (
      <div className='compare-details-wrapper application-wrapper'>
        <h3 className='card_heading'>{promoterData?.fullName}</h3>
        <div className='row m-20'>
          <div className='col-md-4 '>
            <div className='entity_container_heading'>Liveness Score</div>
            <div className='entity_container_value'>
              {faceLiveness?.score || '-'}
            </div>
          </div>
          <div className='col-md-4'>
            <div className='entity_container_heading'>Liveness Result</div>
            <div className='entity_container_value'>
              {' '}
              {faceLiveness?.result || '-'}
            </div>
          </div>
          <div className='col-md-4'>
            <div className='entity_container_heading'>Error</div>
            <div className='entity_container_value'>
              {faceLiveness?.errors[0] || '-'}
            </div>
          </div>
        </div>
        <img
          src={photos?.profileDocument?.fileUrl}
          alt='user_profile'
          className='promoter_img'
        />
      </div>
    );
  }
}
