import React, { Component } from 'react';
import { POST } from '../../../../../utils/webAPI.service';
import { API_LENDER_UPDATE } from '../../../../../utils/APIUrls';
export class LimitandUdyamUpdate extends Component {
  updateLoanandUdhyam = () => {
    const { loanId } = this.props;
    const { preApprovedCreditLimit, udyamNumber } = this.state || {};
    if (
      (!preApprovedCreditLimit || preApprovedCreditLimit.trim() === '') &&
      (!udyamNumber || udyamNumber.trim() === '')
    ) {
      alert('Enter Limit Amount or Udyam Number.');
      return;
    }
    let sendData;
    if (preApprovedCreditLimit && (!udyamNumber || udyamNumber === '')) {
      sendData = {
        limitInfo: {
          preApprovedCreditLimit: preApprovedCreditLimit?.trim(),
        },
      };
    } else if (
      udyamNumber &&
      (!preApprovedCreditLimit || preApprovedCreditLimit === '')
    ) {
      sendData = {
        udyamInfo: {
          udyam: true,
          udyamNumber: udyamNumber?.trim(),
        },
      };
    } else if (preApprovedCreditLimit && udyamNumber) {
      sendData = {
        limitInfo: {
          preApprovedCreditLimit: preApprovedCreditLimit?.trim(),
        },
        udyamInfo: {
          udyam: true,
          udyamNumber: udyamNumber?.trim(),
        },
      };
    }

    POST(API_LENDER_UPDATE(loanId), sendData)
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Data updated successfully');
          this.props.closeAction();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  onInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <>
        <div className='modal-wrapper'>
          <h3 className='card_heading'>Update Limit Amount or Udyam Details</h3>
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='input_heading'>
                Please Enter Limit Amount
                <input
                  placeholder='Requested Limit Amount'
                  required
                  name='preApprovedCreditLimit'
                  onChange={this.onInputChange}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                Please Enter Udyam Number
                <input
                  placeholder='Udyam Number'
                  required
                  name='udyamNumber'
                  onChange={this.onInputChange}
                />
              </div>
            </div>
          </div>
          <div className='save_button mt-5'>
            <button
              className='save_button button-success'
              onClick={this.updateLoanandUdhyam}
            >
              Confirm
            </button>
          </div>
        </div>
      </>
    );
  }
}
