import axios from 'axios';
import React, { Component } from 'react';
import { API_UPLOAD_BANKING } from '../../../../../utils/APIUrls';

class UploadBanking extends Component {
  state = {
    bankName: '',
    pdfPassword: '',
    banks: {},
    selectedBank: '',
  };

  fetchBankData = async () => {
    const url =
      'https://d10pv0up1z895b.cloudfront.net/BANK_MASTER_LIST/1735295971190_Master_Bank_Name.json?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vZDEwcHYwdXAxejg5NWIuY2xvdWRmcm9udC5uZXQvQkFOS19NQVNURVJfTElTVC8xNzM1Mjk1OTcxMTkwX01hc3Rlcl9CYW5rX05hbWUuanNvbiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6NDg4ODg5NTk3MX19fV19&Signature=vo41I9cSJHzZFFquo3vEko8dESIFUX3-fr2ryPBjvQq8BYf7EZwtVIBxAQo2j9uDBbEa3hh8DlFQzXJ88JXo4llLUzRvTg9fR2XVKZjZtLC5rJoXjXisOjw3xF9Jcg4lPiwFlThfAxZwPMXY-GVODoD8HmQJo~ZtpL9QVM5ZAinrCUpsad8Nq-bWnAihUo85FzmnYmqhWE-FYO0MpY~V8x5X5B7xZ-rZh0UCR2UESpmjZUizO3QvviRHuTw556ShnG8BlfrM0Ms5lwQuBlKZlipbFj1IiFcw328s0xCrLKMzDjoRgR2FtR0E0aRzEPMSxYYP3iKrWb05-tdX6CmDoQ__&Key-Pair-Id=K3GAO14QEP4X71'; // Replace with your API URL

    const response = await axios.get(url);
    this.setState({ banks: response.data });
  };

  componentDidMount() {
    this.fetchBankData();
  }

  handleSelectionChange = (event) => {
    const selectedKey = event.target.value;
    this.setState({ selectedBank: selectedKey });
  };

  handlePasswordChange = (event) => {
    this.setState({ pdfPassword: event.target.value });
  };

  handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    const { pdfPassword, selectedBank } = this.state;
    const token = localStorage.getItem('token');
    const User = localStorage.getItem('userId');
    const { loanId, closeAction, getBankingList } = this.props;

    if (!selectedBank) {
      alert('Please select a bank name');
      return;
    }

    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/form-data',
      'X-CLIENT-NAME': 'PDU',
      'X-USER-ID': User,
    };

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    const url = `${API_UPLOAD_BANKING(
      loanId
    )}?bankId=${selectedBank}&password=${pdfPassword}`;

    await axios
      .post(url, formData, { headers })
      .then(async ({ data }) => {
        if (data?.code === 200) {
          alert('File uploaded successfully!');
          getBankingList();
          closeAction();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        console.log(err, 'err');
        alert('Something went wrong');
        throw err;
      });
  };

  render() {
    const { banks, selectedBank, pdfPassword } = this.state;
    return (
      <>
        <div className='modal_card services-wrapper'>
          <h3 className='modal_card-heading'>Upload Bank Stmt</h3>
        </div>

        <div className='modal-wrapper'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='input_heading'>
                <div>Select Bank Name</div>
                <select
                  className='select-box'
                  name='accountType'
                  onChange={this.handleSelectionChange}
                  value={selectedBank}
                >
                  <option value='' disabled>
                    -- Select a Bank --
                  </option>
                  {Object.entries(banks)?.map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='input_heading'>
                <div>Enter Password for PDF</div>
                <input
                  placeholder='Password for PDF'
                  value={pdfPassword}
                  onChange={this.handlePasswordChange}
                />
              </div>
            </div>
          </div>
          <div className='save_button mt-5'>
            <label htmlFor='file-upload' className='save_button button-success'>
              <input
                id='file-upload'
                type='file'
                onChange={this.handleFileChange}
                style={{ display: 'none' }}
                accept='application/pdf,image/*'
                multiple
              />
              Upload Banking
            </label>
          </div>
        </div>
      </>
    );
  }
}

export default UploadBanking;
