import { combineReducers } from 'redux';
import { listData } from './listData.reducers';
import ApplicationReducer from '../views/LeadManagement/Application/Overview/application.reducers';
import offerReducer from '../views/LeadManagement/Offer/offer.reducers';
import invoicesReducer from '../views/LeadManagement/Invoices/invoices.reducers';
import { leadCreditBehaviour } from '../views/LeadManagement/Application/CreditBehaviour/creditBehaviour.reducers';
import interestAndChargesReducer from '../views/LeadManagement/InterestAndCharges/interestAndCharges.reducers';
import leadManagementNavsReducer from '../views/LeadManagement/LeadManagementNavs/LeadManagementNavs.reducers';
import manageUserReducer from '../views/LeadManagement/ManageUser/manageUser.reducers';
import paymentsReducer from '../views/LeadManagement/Payments/payments.reducers';
import eodManagementReducer from '../views/EodManagement/eodManagement.reducers';
import creditScoreReducer from '../views/LeadManagement/Application/CreditScoreCard/CreditScoreCard.reducers';
import gstReducer from '../views/LeadManagement/Application/Gst/gst.reducer';
import { bankingReducer } from '../views/LeadManagement/Application/Banking/banking.reducer';
import AllInvoicesReducer from '../views/InvoiceManagement/ALLInvoices/allInvoices.reducers';
import UnderReviewInvoicesReducer from '../views/InvoiceManagement/UnderReviewInvoices/underReviewInvoices.reducer';
import FailedInvoicesReducer from '../views/InvoiceManagement/FailedInvoices/failedInvoices.reducer';
import DisbursedInvoicesReducer from '../views/InvoiceManagement/DisbursedInvoices/disbursedInvoices.reducer';
import FundPendingInvoicesReducer from '../views/InvoiceManagement/FundPendingInvoices/fundPendingInvoices.reducer';
import HoldInvoicesReducer from '../views/InvoiceManagement/OnHoldInvoices/onHoldInvoices.reducer';
import MarginReducer from '../views/InvoiceManagement/MarginInvoices/marginInvoices.reducer';
import DraftedReducer from '../views/InvoiceManagement/DraftedInvoices/draftedInvoices.reducer';
import SupplierDetailsReducer from '../views/AnchorManagement/SupplierDashboard/Profile/profile.reducers';
import invitationReducer from '../views/AnchorManagement/SupplierDashboard/Invitation/invite.reducers';
import supplierInvoicesReducer from '../views/AnchorManagement/SupplierDashboard/Invoices/invoice.reducers';
import activePartiesReducer from '../views/AnchorManagement/SupplierDashboard/ActiveParties/activeParties.reducers';
import homeReducer from '../views/AnchorManagement/SupplierDashboard/Home/home.reducers';
import anchorReducer from '../views/AnchorManagement/Dashboard/dashboard.reducers';
import LenderServicesReducer from '../views/LeadManagement/Application/LenderService/lenderService.reducers';
const initialState = {
  isAuthenticated: false,
  isLogin: false,
  loaderModal: false,
  activeDashboard: 'user_dashboard',
};
function data(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADER_MODAL_STATUS':
      return {
        ...state,
        loaderModal: action.val,
      };
    case 'SET_ACTIVE_DASHBOARD':
      return {
        ...state,
        activeDashboard: action.val,
      };

    default:
      return state;
  }
}

const reducers = combineReducers({
  data,
  listData,
  ApplicationReducer,
  anchorReducer,
  invitationReducer,
  offerReducer,
  invoicesReducer,
  leadCreditBehaviour,
  interestAndChargesReducer,
  leadManagementNavsReducer,
  manageUserReducer,
  paymentsReducer,
  eodManagementReducer,
  creditScoreReducer,
  gstReducer,
  AllInvoicesReducer,
  FailedInvoicesReducer,
  UnderReviewInvoicesReducer,
  FundPendingInvoicesReducer,
  MarginReducer,
  DisbursedInvoicesReducer,
  HoldInvoicesReducer,
  DraftedReducer,
  bankingReducer,
  SupplierDetailsReducer,
  supplierInvoicesReducer,
  activePartiesReducer,
  homeReducer,
  LenderServicesReducer,
});

export default reducers;
