import * as lenderServiceConstants from './lenderService.action.constant';

import { GET, POST } from '../../../../utils/webAPI.service';
import { API_BANKING_LIST } from '../../../../utils/APIUrls';
const getBankingList = (loanId) => {
  return (dispatch) => {
    return GET(API_BANKING_LIST(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: lenderServiceConstants.SET_BANKING_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export { getBankingList };
