import * as lenderServiceConstants from './lenderService.action.constant';

const initialState = {
  bankDetails: [],
};

const LenderServicesReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case lenderServiceConstants.SET_BANKING_DETAILS:
      return { ...state, bankDetails: data };
    default:
      return state;
  }
};

export default LenderServicesReducer;
