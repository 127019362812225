//Environment Variable
const BASE_URL_PEMANT = process.env.REACT_APP_BASE_URL_PEMANT;
const BASE_URL_PEMANT_C1 = process.env.REACT_APP_BASE_URL_PEMANT_C1;
const REACT_APP_BASE_URL_GST = process.env.REACT_APP_BASE_URL_GST;
const REACT_APP_BASE_URL_BANKING = process.env.REACT_APP_BASE_URL_BANKING;
const REACT_APP_BASE_URL_PEMANT_C2 = process.env.REACT_APP_BASE_URL_PEMANT_C2;

// User Application page APIS
const API_POST_LOANS = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/allApplication`,
  API_GET_BUSINESS_DETAILS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/business-details/${loanId}`,
  API_GET_DIGILOCKER_DETAILS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/kyc/fetch/${loanId}`,
  API_GET_ADDRESS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/fetch/address/${loanId}`,
  API_GET_PHOTOS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/doc/${loanId}`,
  API_GET_BANK_DETAILS = (userId) =>
    `${BASE_URL_PEMANT}/user/v1/bank/fetch/bank-details/${userId}`,
  API_GET_DEVICE_DETAILS = (mobileNumber) =>
    `${BASE_URL_PEMANT}/user/v1/user/fetch/${mobileNumber}`,
  API_GET_CPO_DOCUMENTS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/entity-doc-list/${loanId}`,
  API_GET_SUPPLIER_DETAILS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/supplier-details/${loanId}`,
  API_GET_CPO_DOCUMENTS_ID = (docId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/entity-doc/${docId}`,
  API_GET_COMAPRE_KYC_DETAILS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/kyc/fetch/compare/${loanId}`,
  API_POST_DOCUMENT_VERIFY = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/doc-verification-status`,
  API_GET_DOCUMENT_VERIFICATION_STATUS = (loanId, docId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/doc-verification-status/${loanId}/${docId}`,
  API_GET_COMAPRE_DETAILS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/compare/${loanId}`,
  API_GET_NOTES = (loanId, type) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/notes/${loanId}/${type}`,
  API_POST_NOTES = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/add/notes`,
  API_POST_CREATE_OFFER = `${BASE_URL_PEMANT}/user/v1/offer/create`,
  API_POST_REJECT_OFFER = (loanId) =>
    `${BASE_URL_PEMANT}/user/v1/offer/reject/${loanId}`,
  API_GET_TRANSACTION_ANALYSIS = (
    loanId,
    mobileNumber
  ) => `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/transaction/${loanId}/${mobileNumber}
  `,
  API_POST_DEDUPE_STATUS = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/get/dedupe/status`,
  API_POST_SMS_EXCEL = `${BASE_URL_PEMANT}/user/v1/user/excel-url`,
  API_GET_PROGRAM_NAMES_LIST = (supplierId) =>
    `${BASE_URL_PEMANT_C1}/product/v1/program/fetch/program-names/${supplierId}`,
  API_GET_SUB_PROGRAM_NAMES_LIST = (supplierId, programName) =>
    `${BASE_URL_PEMANT_C1}/product/v1/program/fetch/sub-program-names/${supplierId}/${programName}`,
  API_FETCH_SUB_PROGRAM_DETAILS = `${BASE_URL_PEMANT_C1}/product/v1/program/fetch/sub-program`,
  //Dashboard apis
  API_GET_TRACKER = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/tracker/${loanId}`,
  API_POST_SEARCH_USER = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/search/user`,
  // Login APIS
  API_POST_LOGIN = `${BASE_URL_PEMANT}/user/v1/user/login`,
  // Credit Behaviour  page APIS
  API_FETCH_ANALYSIS = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/analysis/${loanId}`,
  API_GET_APPLICANT_SUMMARY = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch-applicants-summary/${loanId}`,
  API_GET_BUREAU_HIT = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/bureau-analysis/${loanId}`,
  API_POST_UPDATE_BUREAU_TRADE_LINE = (applicationNumber) =>
    `/updateBureauTradeLine/${applicationNumber}`,
  API_GET_CREDIT_RULES = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/credit-behaviour-rules/${loanId}`,
  API_SUBMIT_PROFILE = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/submit/profile-photo/${loanId}`,
  // Cam apis
  API_GET_CAM_DATA = (userId) =>
    `${BASE_URL_PEMANT}/assessment/v1/credit/fetch/data/${userId}`,
  API_POST_CAM_DATA = `${BASE_URL_PEMANT}/assessment/v1/credit/save`,
  // Credit Score Card APIS
  API_GET_CREDIT_INPUT_DATA = (userId) =>
    `${BASE_URL_PEMANT}/assessment/v1/credit/fetch/input/${userId}`,
  API_GET_CREDIT_RULES_DATA = (userId) =>
    `${BASE_URL_PEMANT}/assessment/v1/credit/fetch/rules/${userId}`,
  API_GET_CREDIT_BENCHMARK_DATA = `${BASE_URL_PEMANT}/assessment/v1/credit/fetch/benchmark`,
  //Ledger apis
  API_GET_LEDGER_INFO = (userId, supplierId) =>
    `${BASE_URL_PEMANT}/assessment/v1/ledger/ledger-account/${userId}/${supplierId}`,
  // Offer page APIS
  API_GET_ACTIVE_LOAN = (loanId) =>
    `${BASE_URL_PEMANT}/user/v1/offer/fetch/active/${loanId}`,
  API_GET_PROGRAMS = (productName) =>
    `${BASE_URL_PEMANT_C1}/product/v1/product/fetch/programs/${productName}`,
  API_POST_SUB_PROGRAM = `${BASE_URL_PEMANT_C1}/product/v1/program/fetch/sub-program`,
  API_POST_OFFER_ACTIVE = `${BASE_URL_PEMANT}/user/v1/offer/activate`,
  API_POST_UPDATE_LIMIT_STATUS = `${BASE_URL_PEMANT_C1}/invoice/v1/limit/update/status`,
  API_GET_ALL_OFFERS = (loanId) =>
    `${BASE_URL_PEMANT}/user/v1/offer/fetch/all/${loanId}`,
  API_GET_OFFER_HISTORY = (loanId) =>
    `${BASE_URL_PEMANT}/user/v1/offer/fetch/history/${loanId}`,
  API_GET_OFFER_REGERATE_CHECK = (loanId) =>
    `${BASE_URL_PEMANT}/user/v1/offer/regenerate-check/${loanId} `,
  API_GET_UNLOCK_OFFER = (loanId) =>
    `${BASE_URL_PEMANT}/user/v1/offer/unlock/${loanId}`,
  // Manage user APIS
  API_POST_CUSTOMER_AND_SUPPLIER_LIST = `${BASE_URL_PEMANT}/user/v1/onboarding/dashboard/contacts`,
  API_ADD_SUPPLIER = `${BASE_URL_PEMANT}/user/v1/onboarding/add/supplier`,
  API_POST_UPDATE_USER_STATUS = `${BASE_URL_PEMANT}/user/v1/onboarding/update/contact/status`,
  API_GET_FUNDING_SUMMARY = (userId) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/fetch/funding-summary/${userId}`,
  API_GET_SUPPLIER_PAYMENT_DETAILS = (supplierId) =>
    `${BASE_URL_PEMANT}/user/v1/onboarding/supplier/payment-details/${supplierId}`,
  API_SEARCH_SUPPLIER = (mobileNumber) =>
    `${BASE_URL_PEMANT}/user/v1/supplier/${mobileNumber}`,
  // Invoices APIS
  API_GET_VERIFIED_SUPPLIERS = (userId) =>
    `${BASE_URL_PEMANT}/user/v1/onboarding/fetch/verified-suppliers/${userId}`,
  API_POST_UPLOAD_INVOICE = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/upload/invoice`,
  API_POST_CONTACT = `${BASE_URL_PEMANT}/user/v1/onboarding/fetch/contact`,
  API_GET_LIMIT_SUMMARY = (userId) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/limit/summary/${userId}`,
  API_GET_INVOICE_SUMMARY = (userId) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/invoice/summary/${userId}`,
  API_POST_INVOICES = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/fetch/invoices`,
  API_POST_INVOICE_DETAILS = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/fetch/invoice-details`,
  API_POST_MARGIN_RELEASE = `${BASE_URL_PEMANT_C1}/invoice/v1/balance-overview/margin/release`,
  API_POST_REJECT_COMMENT = `${BASE_URL_PEMANT_C1}/user/v1/offer/reject-loan-offer`,
  // Payments APIS
  API_POST_ADD_PAYMENTS = `${BASE_URL_PEMANT_C1}/lms/v1/disbursal/add-payment`,
  API_POST_PAYMENT_HISTORY = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/fetch/payment-history`,
  API_POST_PAYMENTS_DETAILS = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/fetch/payment-details`,
  API_POST_FETCH_REPAYMENTS = `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/fetch/re-payments`,
  API_GET_REPAYMENT_DETAILS = (paymentId) =>
    `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/fetch/repayment/details/${paymentId}`,
  //SOA APIS
  API_GET_DASHBOARD_SOA = `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/getsoa`,
  API_POST_ACCRUED_ENTRIES = (userId, supplierId) =>
    `${BASE_URL_PEMANT_C1}/lms/v1/interest/manual?userId=${userId}&supplierId=${supplierId}`,
  API_GET_FETCH_CHARGES = `${BASE_URL_PEMANT_C1}/product/v1/master/fetch/charges`,
  API_POST_FETCH_CHARGE = `${BASE_URL_PEMANT_C1}/product/v1/master/fetch/charge`,
  API_POST_FETCH_GST_CHARGE = `${BASE_URL_PEMANT_C1}/product/v1/master/fetch/gst`,
  API_POST_DASHBOARD_CHARGE_POST = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/post/charge`,
  API_POST_DASHBOARD_FETCH_CHARGES = `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/fetch/charges`,
  API_POST_CHARGE_WAIVE_OFF = `${BASE_URL_PEMANT_C1}/lms/v1/disbursal/waiveoff`,
  API_GET_SMA_DETAILS = (userId) =>
    `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/dpd-info/${userId}`,
  API_GET_SOA_SUMMARY = (userId) =>
    `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/getsummary/${userId}`,
  API_GET_ADMIN_SOA_SUMMARY = (userId) =>
    `${BASE_URL_PEMANT_C1}/lms/v1/soa/summary/${userId}`,
  API_POST_ADMIN_TABLE = `${BASE_URL_PEMANT_C1}/lms/v1/soa/fetch/admin`,
  API_ADMIN_SOA_PDF = `${BASE_URL_PEMANT_C1}/lms/v1/soa/download`,
  API_GET_LIMIT_ACCOUNT_NUMBER = (userId) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/limit/fetch/limit-account-number/${userId}`,
  API_GET_SOA_EXCEL = `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/exportsoa/excel`,
  API_GET_EXPORT_SOA_EXCEL = `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/exportsoa/excel`,
  API_GET_SOA_INVOICE_DETAILS = (invoiceId) =>
    `${BASE_URL_PEMANT_C1}/lms/v1/dashboard/fetch/invoice-details/${invoiceId}`,
  //EOD APIS
  API_GET_EOD_SCHEDULE = `${BASE_URL_PEMANT_C1}/audit/v1/eod/schedule`,
  API_GET_EOD_HISTORY = `${BASE_URL_PEMANT_C1}/audit/v1/eod/history`,
  API_POST_EOD_START = `${BASE_URL_PEMANT_C1}/audit/v1/eod/start`,
  API_POST__SOD_START = `${BASE_URL_PEMANT_C1}/audit/v1/eod/sod`,
  //SERVICES APIS
  API_GET_ACTIVATE_LIMIT = (loanId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/activate-limit/${loanId}`,
  API_GET_GENERATE_MPIN = (userId) =>
    `${BASE_URL_PEMANT}/user/v1/mpin/generate/${userId}`;
const API_POST_CLOSE_LIMIT = `${BASE_URL_PEMANT_C1}/invoice/v1/limit/close`;
//Banking APIS
const API_BANK_DETAILS = (appNo) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/getBankDetails/${appNo}`,
  API_BANK_ANALYSIS = (appNo) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/getBankingAnalysis/${appNo}`,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER = (appNo, accNo) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/getBankingAnalysis/${appNo}/${accNo}`,
  API_EDIT_BOUNCE_RATIO = (appNo, status) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/editBounceRatio/${appNo}/${status}`,
  API_EDIT_BOUNCE_RATIO_ACC = (appNo, status, accountNumber) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/editBounceRatio/${appNo}/${status}/${accountNumber}`,
  API_UPDATE_SANCTIONED_LIMIT = '/updateSanctionedLimit',
  API_SUBMIT_BOUNCE_RATIO = (applicationNumber, status) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/submitBounceRatio/${applicationNumber}/${status}`,
  API_SUBMIT_BOUNCE_RATIO_ACC = (applicationNumber, status, accountNumber) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/submitBounceRatio/${applicationNumber}/${status}/${accountNumber}`,
  API_GET_BANK_ACCOUNT_FLAGS = (applicationNumber, accountNumber) =>
    `${REACT_APP_BASE_URL_BANKING}/integration/getBankAccountFlags/${applicationNumber}/${accountNumber}`,
  API_GET_B2B_APPLICATION_ID = (userId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/b2bApplicationId/${userId}`,
  API_BANK_STATEMENT_DOWNLOAD = (applicationID) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/document/BANK_STATEMENT/${applicationID}`,
  API_BANKING_EXCEL_DOWNLOAD = (applicationID) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/document/EXCEL/${applicationID}`;

// Applicant Guide
const API_GET_ENTITY_NAME = (appNo) => `/getEntityName/${appNo}`;

// Credit GST
const API_FETCH_GST_ANNUAL_ANALYSIS = (appNo, gstin) =>
    `${REACT_APP_BASE_URL_GST}/integration/fetchGstrAnnualAnalysis/${appNo}/${gstin}`,
  API_GET_GST_ANALYSIS = (applicationNumber) =>
    `${REACT_APP_BASE_URL_GST}/integration/getGstAnalysis/${applicationNumber}`;
const API_POST_ACTIVE_LIMIT = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/activate-limit`;
const API_FETCH_TRANSACTION_RULES = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/fetch/transaction-Rules`;

//Gst Manual
const API_GST_GET_DETAILS = (gstin) =>
  `${REACT_APP_BASE_URL_GST}/integration/getTaxPayerData/${gstin}`;
const API_GET_GST_MANUAL_TURNOVER = (gstin) =>
  `${REACT_APP_BASE_URL_GST}/integration/manual-gst-turnover?gstNumber=${gstin}`;
const API_POST_GST_MANUAL = `${REACT_APP_BASE_URL_GST}/integration/manual-gst-turnover`;

// Invoice Dashboard
const API_GET_INVOICE_LIST = `${BASE_URL_PEMANT_C1}/invoice/v1/invoice`,
  API_GET_INVOICE_SUMMARY_LIST = `${BASE_URL_PEMANT_C1}/invoice/v1/invoice/summary`,
  API_POST_DOWNLOAD_DISBURSED_INVOICES = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/download/disbursed-invoices`,
  API_POST_UPLOAD_BANK_PDF = `${BASE_URL_PEMANT_C1}/invoice/v1/lendar/disbursal/feedback`,
  API_POST_REVIEW_INVOICES = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/review/invoices`,
  API_GET_LOAN_INFO = (userId) =>
    `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/fetch-loan-info/${userId}`,
  API_GET_RULES_FAILED = (invoiceID) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/invoice/failed-rules-details/${invoiceID}`,
  API_POST_DOWNLOAD_PENDING_INVOICES = `${BASE_URL_PEMANT_C1}/invoice/v1/lendar/pending/disbursal`,
  API_POST_APPROVE_BANK_INVOICES = `${BASE_URL_PEMANT_C1}/invoice/v1/dashboard/retry/bank-failed`,
  API_POST_MARGIN_LIST = `${BASE_URL_PEMANT_C1}/invoice/v1/balance-overview/fetch/margins`,
  API_GET_EINVOICE_FILE = (invoiceID) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/invoice/fetch/verified/e-invoice/${invoiceID}`,
  API_GET_MARGIN_SUMMARY = `${BASE_URL_PEMANT_C1}/invoice/v1/balance-overview/margin/summary`,
  API_GET_MARGIN_DETAILS = (invoiceID) =>
    `${BASE_URL_PEMANT_C1}/invoice/v1/balance-overview/margin/details/${invoiceID}`;
const API_GET_REJECT_INVOICES = (invoiceID) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/invoice/prepay-undo/${invoiceID}`;
const API_POST_SEND_INVOICE_LENDER = (userId, invoiceID, lenderId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/lender/invoice-push/${userId}/${invoiceID}/${lenderId}`;
// Supplier Dashboard
const API_SUPPLIER_DETAILS = (anchorId) =>
  `${BASE_URL_PEMANT}/user/v1/supplier/fetch/details/${anchorId}`;
const API_BUYER_INVITATION = `${BASE_URL_PEMANT}/user/v1/supplier/fetch/buyers`;
const API_INVITATION_SENT = (anchorId, mobileNumber) =>
  `${BASE_URL_PEMANT}/user/v1/invitation/send/again/${anchorId}/${mobileNumber}`;
const API_BUYER_GST_SEARCH = (anchorId, gst) =>
  `${BASE_URL_PEMANT}/user/v1/supplier/search/buyer/${anchorId}/${gst}`;
const API_BUYER_INVITATION_SENT = `${BASE_URL_PEMANT}/user/v1/invitation/send`;
const API_UPDATE_BANK_DETAILS = `${BASE_URL_PEMANT}/user/v1/bank/account-management`;
const API_IFSC_VERIFY = (ifsc) =>
  `${BASE_URL_PEMANT}/user/v1/bank/verify-ifsc/${ifsc}`;
const API_PAYMENT_VIEW = `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/fetch/payment-history`;
const API_INVOICES_VIEW = (anchorId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/invoice-list/${anchorId}`;
const API_PAYMENT_EXCEL = (anchorId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/download/INVOICE_PAYMENT_EXCEL/${anchorId}`;
const API_PAYMENT_MODAL = `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/fetch/invoice-breakup`;
const API_INVOICE_FAILED_EXCEL = (anchorId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=INVOICE_FAILED_EXCEL&supplierId=${anchorId}`;
const API_INVOICE_PAID_EXCEL = (anchorId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=INVOICE_PAID_EXCEL&supplierId=${anchorId}`;
const API_INVOICE_UNPAID_EXCEL = (anchorId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=INVOICE_UNPAID_EXCEL&supplierId=${anchorId}`;
const API_ACTIVE_PARTIES_EXCEL = (anchorId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=ACTIVE_PARTIES_EXCEL&supplierId=${anchorId}`;
const API_INVOICE_BUYER_FAILED_EXCEL = (anchorId, buyerId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=INVOICE_FAILED_EXCEL&supplierId=${anchorId}&userId=${buyerId}`;
const API_INVOICE_BUYER_PAID_EXCEL = (anchorId, buyerId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=INVOICE_PAID_EXCEL&supplierId=${anchorId}&userId=${buyerId}`;
const API_INVOICE_BUYER_UNPAID_EXCEL = (anchorId, buyerId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/mis/download?docName=INVOICE_UNPAID_EXCEL&supplierId=${anchorId}&userId=${buyerId}`;
// Active Parties (Supplier Dashboard)
const API_GET_ACTIVE_PARTIES = `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/active-parties`;
const API_GET_BUYER_SUMMARY = (supplierId, buyerId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/invoice-summary/${supplierId}/${buyerId}`;
//BuyerDetails
const API_BUYER_INVOICES = (supplierId, buyerId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/invoice-list/${supplierId}/${buyerId}`;
const API_BUYER_PAYMENT_EXCEL = (supplierId, buyerId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/download/INVOICE_PAYMENT_EXCEL/${supplierId}/${buyerId}`;
// Home (Supplier Dashboard)
const API_GET_SUPPLIER_SUMMARY = (supplierId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v2/dashboard/invoice-summary/${supplierId}`;
//Anchor Dashboard
const API_ANCHOR_LIST = `${BASE_URL_PEMANT}/user/v1/user/anchors-list`;
//Add Program
const API_LENDER_LIST = `${BASE_URL_PEMANT_C1}/product/v1/supplier/lender`;
const API_ADD_PROGRAM = `${BASE_URL_PEMANT_C1}/product/v1/program/add-program`;
const API_ADD_SUB_PROGRAM = `${BASE_URL_PEMANT_C1}/product/v1/program/add/anchor-sub-program`;
const API_FETCH_PROGRAM = (appId) =>
  `${BASE_URL_PEMANT_C1}/product/v1/program/fetch-programs/${appId}`;
const API_USER_INFO = (mob) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/fetch/loanInfo/${mob}`;
//Vendor-offer
const API_PROGRAM_LIST = (supplierId) =>
  `${BASE_URL_PEMANT_C1}/product/v1/program/fetch/program-names/${supplierId}`;
const API_SUB_PROGRAM_DETAILS = (supplierId, programName) =>
  `${BASE_URL_PEMANT_C1}/product/v1/program/sub-programs/${supplierId}/${programName}`;
const API_VENDOR_OFFER_DETAILS = (userId, supplierId) =>
  `${BASE_URL_PEMANT_C1}/invoice/v1/limit/vendor-limit/${userId}/${supplierId}`;
const API_ACTIVATE_VENDOR_LIMIT = `${BASE_URL_PEMANT}/user/v1/vendor/add-limit`;
const API_LENDERS_LIST = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/lender/${loanId}`;
const API_TAG_LENDER = `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/tag-lender`;
const API_CHECK_LENDER_ELIGIBILITY = (loanId, lenderId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/check-eligibility/${loanId}/${lenderId}`;

//Lender
const API_CREATE_LOS = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/create-loan/${loanId}`;
const API_COMPLETE_ONBOARDING = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/complete-onboarding/${loanId}`;
const API_ESIGN = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/get-esign-url/${loanId}`;
const API_EMANDATE = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/get-mandate-link/${loanId}`;
const API_LOAN_STATUS = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/loan-status/${loanId}`;
const API_LENDER_STAGE = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/stages/${loanId}`;
const API_DOC_LIST = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/loan/doc-list?loanId=${loanId}`;
const API_LENDER_UPDATE = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/update/${loanId}`;
const API_LENDER_SUMMARY = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/dashboard/lender-summary/${loanId}`;
const API_LENDER_SHARED = (loanId, docType, docName) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/upload-document?loanId=${loanId}&docType=${docType}&docName=${docName}`;
const API_BANKING_LIST = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/${loanId}`;
const API_TRIGGER_BANK_STMT = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/lender/upload/${loanId}`;
const API_UPLOAD_BANKING = (loanId) =>
  `${REACT_APP_BASE_URL_PEMANT_C2}/loan/v1/doc/upload/${loanId}`;

export {
  API_PAYMENT_MODAL,
  API_BANKING_LIST,
  API_TRIGGER_BANK_STMT,
  API_LENDERS_LIST,
  API_TAG_LENDER,
  API_CHECK_LENDER_ELIGIBILITY,
  API_CREATE_LOS,
  API_LENDER_UPDATE,
  API_LENDER_SHARED,
  API_ESIGN,
  API_DOC_LIST,
  API_LENDER_SUMMARY,
  API_LOAN_STATUS,
  API_EMANDATE,
  API_LENDER_STAGE,
  API_COMPLETE_ONBOARDING,
  API_INVOICE_FAILED_EXCEL,
  API_POST_SEND_INVOICE_LENDER,
  API_SUBMIT_PROFILE,
  API_POST_REJECT_COMMENT,
  API_INVOICE_BUYER_FAILED_EXCEL,
  API_INVOICE_BUYER_PAID_EXCEL,
  API_INVOICE_BUYER_UNPAID_EXCEL,
  API_INVOICE_PAID_EXCEL,
  API_ACTIVE_PARTIES_EXCEL,
  API_INVOICE_UNPAID_EXCEL,
  API_GET_SMA_DETAILS,
  API_PROGRAM_LIST,
  API_POST_ACCRUED_ENTRIES,
  API_POST_CLOSE_LIMIT,
  API_ACTIVATE_VENDOR_LIMIT,
  API_VENDOR_OFFER_DETAILS,
  API_SUB_PROGRAM_DETAILS,
  API_USER_INFO,
  API_FETCH_PROGRAM,
  API_ADD_PROGRAM,
  API_ADD_SUB_PROGRAM,
  API_LENDER_LIST,
  API_ANCHOR_LIST,
  API_BUYER_PAYMENT_EXCEL,
  API_SUPPLIER_DETAILS,
  API_BUYER_INVOICES,
  API_PAYMENT_EXCEL,
  API_INVOICES_VIEW,
  API_IFSC_VERIFY,
  API_PAYMENT_VIEW,
  API_UPDATE_BANK_DETAILS,
  API_BUYER_INVITATION_SENT,
  API_INVITATION_SENT,
  API_BUYER_GST_SEARCH,
  API_BUYER_INVITATION,
  API_POST_ADMIN_TABLE,
  API_POST_MARGIN_RELEASE,
  API_ADMIN_SOA_PDF,
  API_GET_ADMIN_SOA_SUMMARY,
  API_SEARCH_SUPPLIER,
  API_GET_COMAPRE_KYC_DETAILS,
  API_GET_REJECT_INVOICES,
  API_GET_EINVOICE_FILE,
  API_GET_MARGIN_DETAILS,
  API_GET_MARGIN_SUMMARY,
  API_POST_MARGIN_LIST,
  API_BANK_STATEMENT_DOWNLOAD,
  API_BANKING_EXCEL_DOWNLOAD,
  API_POST_GST_MANUAL,
  API_GET_GST_MANUAL_TURNOVER,
  API_GST_GET_DETAILS,
  API_GET_RULES_FAILED,
  API_GET_LOAN_INFO,
  API_GET_INVOICE_SUMMARY_LIST,
  API_GET_INVOICE_LIST,
  API_GET_SUPPLIER_DETAILS,
  API_GET_DOCUMENT_VERIFICATION_STATUS,
  API_POST_DOCUMENT_VERIFY,
  API_GET_CPO_DOCUMENTS_ID,
  API_GET_CPO_DOCUMENTS,
  API_GET_DIGILOCKER_DETAILS,
  API_GET_BUREAU_HIT,
  API_POST_LOANS,
  API_GET_BUSINESS_DETAILS,
  API_GET_ADDRESS,
  API_GET_PHOTOS,
  API_GET_BANK_DETAILS,
  API_GET_DEVICE_DETAILS,
  API_GET_COMAPRE_DETAILS,
  API_GET_NOTES,
  API_POST_NOTES,
  API_POST_LOGIN,
  API_FETCH_ANALYSIS,
  API_GET_APPLICANT_SUMMARY,
  API_POST_UPDATE_BUREAU_TRADE_LINE,
  API_POST_CREATE_OFFER,
  API_POST_REJECT_OFFER,
  API_GET_ACTIVE_LOAN,
  API_GET_PROGRAMS,
  API_POST_SUB_PROGRAM,
  API_POST_OFFER_ACTIVE,
  API_POST_UPDATE_LIMIT_STATUS,
  API_ADD_SUPPLIER,
  API_GET_VERIFIED_SUPPLIERS,
  API_POST_CONTACT,
  API_POST_UPLOAD_INVOICE,
  API_POST_ADD_PAYMENTS,
  API_GET_DASHBOARD_SOA,
  API_GET_ALL_OFFERS,
  API_GET_OFFER_HISTORY,
  API_GET_FETCH_CHARGES,
  API_POST_FETCH_CHARGE,
  API_POST_FETCH_GST_CHARGE,
  API_POST_DASHBOARD_CHARGE_POST,
  API_POST_DASHBOARD_FETCH_CHARGES,
  API_POST_CUSTOMER_AND_SUPPLIER_LIST,
  API_POST_UPDATE_USER_STATUS,
  API_POST_CHARGE_WAIVE_OFF,
  API_GET_SOA_SUMMARY,
  API_GET_LIMIT_SUMMARY,
  API_GET_INVOICE_SUMMARY,
  API_POST_PAYMENT_HISTORY,
  API_POST_INVOICES,
  API_POST_INVOICE_DETAILS,
  API_POST_PAYMENTS_DETAILS,
  API_GET_TRANSACTION_ANALYSIS,
  API_GET_OFFER_REGERATE_CHECK,
  API_GET_UNLOCK_OFFER,
  API_GET_FUNDING_SUMMARY,
  API_GET_LIMIT_ACCOUNT_NUMBER,
  API_POST_FETCH_REPAYMENTS,
  API_GET_TRACKER,
  API_GET_REPAYMENT_DETAILS,
  API_POST_SEARCH_USER,
  API_GET_EOD_SCHEDULE,
  API_GET_EOD_HISTORY,
  API_POST_EOD_START,
  API_POST__SOD_START,
  API_GET_SOA_EXCEL,
  API_GET_EXPORT_SOA_EXCEL,
  API_POST_DEDUPE_STATUS,
  API_POST_SMS_EXCEL,
  API_GET_ACTIVATE_LIMIT,
  API_GET_GENERATE_MPIN,
  API_GET_SUPPLIER_PAYMENT_DETAILS,
  API_GET_SOA_INVOICE_DETAILS,
  API_GET_PROGRAM_NAMES_LIST,
  API_GET_SUB_PROGRAM_NAMES_LIST,
  API_FETCH_SUB_PROGRAM_DETAILS,
  API_GET_CREDIT_INPUT_DATA,
  API_GET_CREDIT_BENCHMARK_DATA,
  API_GET_CREDIT_RULES_DATA,
  API_GET_CAM_DATA,
  API_POST_CAM_DATA,
  API_BANK_DETAILS,
  API_BANK_ANALYSIS,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER,
  API_EDIT_BOUNCE_RATIO,
  API_EDIT_BOUNCE_RATIO_ACC,
  API_SUBMIT_BOUNCE_RATIO,
  API_SUBMIT_BOUNCE_RATIO_ACC,
  API_GET_BANK_ACCOUNT_FLAGS,
  API_GET_ENTITY_NAME,
  API_UPDATE_SANCTIONED_LIMIT,
  API_FETCH_GST_ANNUAL_ANALYSIS,
  API_GET_GST_ANALYSIS,
  API_GET_CREDIT_RULES,
  API_GET_B2B_APPLICATION_ID,
  API_GET_LEDGER_INFO,
  API_POST_DOWNLOAD_DISBURSED_INVOICES,
  API_POST_UPLOAD_BANK_PDF,
  API_POST_REVIEW_INVOICES,
  API_POST_DOWNLOAD_PENDING_INVOICES,
  API_POST_APPROVE_BANK_INVOICES,
  API_POST_ACTIVE_LIMIT,
  API_FETCH_TRANSACTION_RULES,
  API_GET_ACTIVE_PARTIES,
  API_GET_BUYER_SUMMARY,
  API_GET_SUPPLIER_SUMMARY,
  API_UPLOAD_BANKING,
};
