import { GET, POST } from '../../../../utils/webAPI.service';
import {
  API_GET_BUSINESS_DETAILS,
  API_GET_ADDRESS,
  API_GET_PHOTOS,
  API_GET_COMAPRE_DETAILS,
  API_GET_BANK_DETAILS,
  API_GET_DEVICE_DETAILS,
  API_GET_NOTES,
  API_GET_TRANSACTION_ANALYSIS,
  API_POST_DEDUPE_STATUS,
  API_GET_PROGRAM_NAMES_LIST,
  API_GET_SUB_PROGRAM_NAMES_LIST,
  API_GET_DIGILOCKER_DETAILS,
  API_GET_CPO_DOCUMENTS,
  API_GET_DOCUMENT_VERIFICATION_STATUS,
  API_GET_COMAPRE_KYC_DETAILS,
  API_GET_SUPPLIER_DETAILS,
  API_LENDERS_LIST,
  API_SUBMIT_PROFILE,
} from '../../../../utils/APIUrls';
import * as applicationConstants from './application.actions.constants';

const getBusinessDetails = (loanId, userId) => {
  return (dispatch) => {
    return GET(API_GET_BUSINESS_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_BUSINESS_DETAILS,
              data: data.data,
            });
          }
          return data;
        }
      })
      .then((data) => {
        if (data) {
          //once we get data from above api
          let { mobileNumber } = data?.data;
          if (data.code === 200) {
            GET(API_GET_DEVICE_DETAILS(mobileNumber))
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: applicationConstants.SET_DEVICE_DETAILS,
                      data: data.data,
                    });
                  }
                }
                return data;
              })
              .then(({ data }) => {
                // dedupe api call once we get the imei numbe
                if (data) {
                  let { imeiNumber } = data;
                  POST(API_POST_DEDUPE_STATUS, { userId, imeiNumber }).then(
                    ({ data }) => {
                      if (data.code === 200) {
                        if (data.data) {
                          dispatch({
                            type: applicationConstants.SET_DEDUPE_DETAILS,
                            data: data.data,
                          });
                        }
                      }
                      return data;
                    }
                  );
                }
              })
              .catch((err) => {
                throw err;
              });

            GET(API_GET_TRANSACTION_ANALYSIS(loanId, mobileNumber))
              .then(({ data }) => {
                if (data.code === 200) {
                  if (data.data) {
                    dispatch({
                      type: applicationConstants.SET_TRANSACTION_DETAILS,
                      data: data.data,
                    });
                  }
                }
                return data;
              })
              .catch((err) => {
                throw err;
              });
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getPhotos = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_PHOTOS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_PHOTOS_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getCompareKycDetails = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_COMAPRE_KYC_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_COMPARE_KYC_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getAddressDetails = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_ADDRESS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_ADDRESS_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getCompareDetails = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_COMAPRE_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_COMPARE_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getCpoDocument = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_CPO_DOCUMENTS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          dispatch({
            type: applicationConstants.SET_CPO_DOCUMENT,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getDigilockerDetails = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_DIGILOCKER_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          dispatch({
            type: applicationConstants.SET_DIGILOCKER_DETAILS,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getBankDetails = (userId) => {
  return (dispatch) => {
    return GET(API_GET_BANK_DETAILS(userId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_BANK_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getSupplierDetails = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_SUPPLIER_DETAILS(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_SUPPLIER_DETAILS,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getSubmitProfilePhoto = (loanId) => {
  return (dispatch) => {
    return GET(API_SUBMIT_PROFILE(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_PROFILE_PHOTO,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getCommentNotes = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_NOTES(loanId, 'comment'))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_COMMENT_NOTES,
              data: data.data.comment,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getConditionNotes = (loanId) => {
  return (dispatch) => {
    return GET(API_GET_NOTES(loanId, 'condition'))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_CONDITION_NOTES,
              data: data.data.comment,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getProgramList = (supplierId) => {
  return (dispatch) => {
    return GET(API_GET_PROGRAM_NAMES_LIST(supplierId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_PROGRAM_LIST,
              data: data.data.responseList,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getSubProgramList = (supplierId, programName) => {
  return (dispatch) => {
    return GET(API_GET_SUB_PROGRAM_NAMES_LIST(supplierId, programName))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_SUB_PROGRAM_LIST,
              data: data.data.responseList,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getDocumentVerifiationStatus = (loanId, docid) => {
  return (dispatch) => {
    return GET(API_GET_DOCUMENT_VERIFICATION_STATUS(loanId, docid))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_VERIFICATION_STATUS,
              data: { status: data.data.status, docid: data.data.docId },
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getLendersList = (loanId) => {
  return (dispatch) => {
    return GET(API_LENDERS_LIST(loanId))
      .then(({ data }) => {
        if (data.code === 200) {
          if (data.data) {
            dispatch({
              type: applicationConstants.SET_LENDERS_LIST,
              data: data.data,
            });
          }
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export {
  getSupplierDetails,
  getCompareKycDetails,
  getDocumentVerifiationStatus,
  getCpoDocument,
  getDigilockerDetails,
  getBusinessDetails,
  getSubmitProfilePhoto,
  getAddressDetails,
  getCompareDetails,
  getConditionNotes,
  getCommentNotes,
  getBankDetails,
  getPhotos,
  getSubProgramList,
  getProgramList,
  getLendersList,
};
